import project1 from "./assets/client-e-portfolio-picture.PNG";
import project2 from "./assets/library-project-1.png";
import project3 from "./assets/NOTFLIX-PIC.PNG";
import project4 from "./assets/netflix-clone-picture.PNG";
import project5 from "./assets/frontend complicated.JPG";
import project6 from "./assets/discord-clone-pic.JPG"

export const data = [
  {
    id: 1,
    projectPic: project1,
    title: "E-commerce App",
    desc: " E-commerce app is an app that allows people to browse their favorite books, pick one of those books, and add them to a cart in order to buy them after.",
    languages: " Html, CSS, JavaScript, React",
    link: "michelmitri1.github.io/client-e-portfolio",
    github: "https://github.com/MichelMitri1/client-e-portfolio",
  },
  {
    id: 2,
    projectPic: project2,
    title: "E-Portfolio",
    desc: " This E-Portfolio was built for a client to help him get jobs and interest recruiters. His name is written as Elon Musk for privacy reasons.",
    languages: "Html, CSS",
    link: "https://library-app-7392b.web.app/",
    github: "https://github.com/MichelMitri1/Library",
  },
  {
    id: 3,
    projectPic: project3,
    title: "NOTFLIX",
    languages: "Html, CSS, Javascript, React",
    desc: "NOTFLIX is a small project that sums up basic functionality of account creation and authentication, searching for a movie/series, browsing more information about the searched movie, and much more.",
    link: "https://netflix-redux-186f3.web.app/",
    github: "https://github.com/MichelMitri1/notflix",
  },
  {
    id: 4,
    projectPic: project4,
    title: "Netflix Clone",
    languages: "Html, CSS, TypeScript, NextJS",
    desc: "This is a netflix loginpage clone that sums up the important functionality of the features present in the actual netflix clone page.",
    link: "https://netflix-clone-14us.vercel.app/",
    github: "https://github.com/MichelMitri1/netflix-clone",
  },
  {
    id: 5,
    projectPic: project5,
    title: "Frontend Simplified Clone",
    languages: "Html, CSS, TypeScript, NextJS",
    desc: " frontend complicated is a project in which the feature of video playing is implemented alongside other important functionalities that a website must hold.",
    link: "https://frontendcomplicated.vercel.app/",
    github: "https://github.com/MichelMitri1/frontendcomplicated",
  },
  {
    id: 6,
    projectPic: project6,
    title: "Discord Clone",
    languages: "Html, CSS, Javascript, React",
    desc: "This is a discord clone that implements the design and the UI of the application along with discord's main functionalities.",
    link: "https://datcord-a8a37.web.app/",
    github: "https://github.com/MichelMitri1/datcord",
  },
];
